import store from "../store/";

const permission = store.state.authData.userPermissions;
let items = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    to: "/",
    group: "dashboard",
  },
];

// if (permission.includes("view_company")) {
//   items.push({
//     title: "Company",
//     icon: "mdi-home",
//     group: "company",
//     to: "/app/company",
//   });
// }

if (permission.includes("view_users")) {
  items.push({
    title: "User",
    icon: "mdi-account-circle",
    group: "user",
    to: "/app/users",
  });
}

if (permission.includes("view_product")) {
  items.push({
    title: "Product",
    icon: "mdi-cart-outline",
    to: "/app/products",
  });
}

/* if (permission.includes("view_stock")) {
  items.push({
    title: "Stock",
    icon: "mdi-sitemap",
    to: "/app/stock",
  });
} */

if (permission.includes("view_sales")) {
  items.push({
    title: "Sales",
    icon: "mdi-book-open-page-variant-outline",
    to: "/app/sales",
  });
}

if (permission.includes("view_roles")) {
  items.push({
    title: "Roles",
    icon: "mdi-air-filter",
    to: "/app/roles",
  });
}

if (permission.includes("view_store")) {
  items.push({
    title: "Stores",
    icon: "mdi-store",
    to: "/app/store",
  });
}

if (permission.includes("view_product_returns")) {
  items.push({
    title: "Product Returns",
    icon: "mdi-cart",
    to: "/app/productReturns",
  });
}

if (permission.includes("view_audit")) {
  items.push({
    title: "Audit Trail",
    icon: "mdi-book-open-outline",
    to: "/app/audit-trail",
  });
}

if (permission.includes("handle_payments")) {
  items.push({
    title: "Billings",
    icon: "mdi-wallet",
    to: "/app/billings",
    group: "billing",
  });
}

if (permission.includes("view_settings")) {
  items.push({
    title: "Settings",
    icon: "mdi-cog",
    to: "/app/settings",
  });
}

items.push({
  title: "Help",
  icon: "mdi-help",
  href: "https://docs.mayventory.com",
});

export default items;
